import Swal from 'sweetalert2'

export const PopUpSwal = (text) => {
  Swal.fire({
    title: `<h2 style="color: #FFF; font-size: 40px; font-weight: 600;">${text}<h2/>`,
    padding: '1vw 3vw',
    background: '#781123',
    showConfirmButton: false,
    showCloseButton: true,
    customClass: {
      closeButton: 'closeModalSwal',
    }
  })
}