const modeEnv = process.env.NODE_ENV || 'development';
let port = ''
let front_port = ''
export const suportMsg = "Hola, hablo desde FDN y necesito ayuda"

// check if mode is in developement or production (Build)
if (modeEnv === 'development') {
  port = ":8000" // :8000
  front_port = ":3000"
}
if (modeEnv === 'production') {
  port = '' // para demoeventos se debe colocar el mismo puerto del BACK ':3018'
  front_port = ""
}

export const API = window.location.protocol + '//' + window.location.hostname + port
export const URI_FRONT = window.location.protocol + '//' + window.location.hostname + front_port
export const ws = window.location.protocol + "//" + window.location.hostname + port
export const uri = process.env.REACT_APP_API_URL
export const adminURL = process.env.REACT_APP_ADMIN_URL
export const urlTyC = "https://fdn.com.co/es/la-fdn/informacion-general/habeas-data"

export const FILE = './pages/certificate/certificados.xlsx'
