import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { suportMsg, API } from '../../config'
import { PopUpSwal } from '../../components/popups/popUpSwal'
import './unsubscribe.css'

const Unsubscribe = () => {
  let phone = "3154296635"
  let country = "+57"
  let urlHelp = `https://wa.me/${country}${phone}?text=${encodeURI(suportMsg)}`

  const [isDeleted, setIsDeleted] = useState(false)
  const [userData, setUserData] = useState({})

  document.title = "FDN | Unsubscribe"

  useEffect(() => {

    if (window.location.search) {
      let parameters = window.location.search.split('?')[1].split('&')
      let paramObj = {}

      parameters.map(ele => {
        let element = ele.split('=')
        if (element[0]) {
          paramObj[element[0]] = decodeURI(element[1])
        }
      })

      setUserData(paramObj)

      if (paramObj.userID) {
        handleClickDeleteOneRegister(paramObj.userID, paramObj.email)
      }

      // console.log(paramObj);
    }

    return () => { /* Unmount */ }
  }, [])

  let handleClickDeleteOneRegister = (uID, email) => {
    let userAgent = navigator.userAgent
    let checker = {
      IOS: userAgent.match(/(iPhone|iPod|iPad)/),
      ANDROID: userAgent.match(/Android/)
    }

    let data = {
      name: `click-unsubscribe`,
      description: "Click en el boton del correo para eliminar de la lista",
      data: {
        component: `lobbyView`,
        name: email,
        device: checker.IOS ? "IOS device" : checker.ANDROID ? "Android device" : "Desktop or Unknown device"
      },
      // user: user._id
    }

    let conf = {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
      },
      body: JSON.stringify(data),
    }

    fetch(`${API}/metrics`, conf)
      .then(res => res.json())
      .then(res => {

        // console.log(res);

        if (res._id) {
          PopUpSwal('Se eliminó correctamente')
          setIsDeleted(true)
        } else if (res.error) {
          if (res.error === `Invalid id`) {
            PopUpSwal(`El usuario ${email} no existe`)
          } else {
            PopUpSwal("Algo salio mal")
          }
          setIsDeleted(false)
        } else {
          PopUpSwal("Algo salio mal")
          setIsDeleted(false)
        }
      })
      .catch(err => {
        console.log(err);
        PopUpSwal("Algo salio mal")
        setIsDeleted(false)
      })
  }

  return (
    <div className="unsubConyainer">
      <div className="container">
        <div className="title">
          <div className="logoFDN"></div>
          <div className="infoTexts">
            <h1>ENCUENTRO DE <br /> INFRAESTRUCTURA <span>2023</span></h1>
            <div className="subtitle">
              <div className="lines">
                <div className="line_1"></div>
                <div className="line_2"></div>
                <div className="line_3"></div>
              </div>
              <h2>
                Retos para una <span>infraestructura</span>
                <br />
                <span>sostenible </span> acorde con las nuevas realidades ambientales, sociales y económicas.
              </h2>
            </div>
            <div className="dateInfo">
              <div className="date">
                <h4>Jueves</h4>
                <p>04 de Mayo 2023</p>
              </div>

              <div className="place">
                <h4>Club el Nogal</h4>
                <p>8:00 a.m. a 5:00 p.m.</p>
              </div>

              <div className="contact">
                <h4>Más información:</h4>
                <div className="wtsCont">
                  <div className="icon"></div>
                  <p>315 4296635</p>
                </div>
                <div className="emailCont">
                  <div className="icon"></div>
                  <p>fdn@encuentroinfraestructura.com</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="formCont">
          <div className="messageCont">
            <h2>Lamentamos que ya no esté con nosotros</h2>
            {
              isDeleted
              ? <p>El usuario {userData.email} fue eliminado</p>
              : <p>estamos procesando la petición...</p>
            }
          </div>
        </div>
      </div>

      <footer>
        <a className="soporte" href={urlHelp} target="_blank" rel="noopener noreferrer">Soporte</a>
      </footer>

      <div className="logoVigilado"></div>
    </div>
  )
}

export default withRouter(Unsubscribe)
